/* src/components/Content.css */
@font-face {
    font-family: 'JMH\ Typewriter';
    src: url('./compages/JMH\ Typewriter.ttf') format('truetype');
    /* Aggiungi altre definizioni di formato per il supporto di diversi tipi di file di font, se necessario */
  }
  
  .content {
    font-family: 'JMH\ Typewriter', Arial, sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: #000000; /* Colore blu di Bootstrap */
    /* Altri stili che desideri applicare */
  }